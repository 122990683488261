import React from 'react'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'

const useStyles = withStyles({
  container: {
    paddingTop: '3rem',
    paddingBottom: '3rem'
  }
})

class Section extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <Container className={this.props.classes.container}>{this.props.children}</Container>
      </div>
    )
  }
}

export default useStyles(Section)
