import React from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import swal from 'sweetalert'
import * as yup from 'yup'
import signup from '../api/signup'

const useStyles = withStyles({
  div: {
    width: '40rem',
    maxWidth: '90vw',
    margin: '0 auto'
  }
})

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  message: yup.string()
})

const initialState = {
  form: {
    email: '',
    firstname: '',
    lastname: '',
    message: "I'd like to learn more about NutroGenome!"
  },
  meta: {
    errors: {}
  }
}

class EmailForm extends React.Component {
  state = { ...initialState }

  handleChange = name => event => {
    this.setState({ form: { ...this.state.form, [name]: event.target.value } })
  }

  field = (label, path, props) => (
    <TextField
      fullWidth
      label={label}
      value={this.state.form[path]}
      onChange={this.handleChange(path)}
      error={!!this.state.meta.errors[path]}
      helperText={this.state.meta.errors[path]}
      {...props}
    ></TextField>
  )

  render() {
    const { classes } = this.props
    return (
      <div className={classes.div}>
        {this.field('Email', 'email')}
        <br />
        <br />
        {this.field('First Name', 'firstname')}
        <br />
        <br />
        {this.field('Last Name', 'lastname')}
        <br />
        <br />
        <br />
        {this.field('Message', 'message', { multiline: true, variant: 'outlined' })}
        <br />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button variant="contained" onClick={this.submit}>
            Submit
          </Button>
        </div>
      </div>
    )
  }
  submit = async () => {
    try {
      await formSchema.validate(this.state.form, { abortEarly: false })
      this.setState({ meta: { ...this.state.meta, errors: {} } })
    } catch (error) {
      const errors = error.inner.reduce((a, b) => ({ ...a, [b.path]: b.errors[0] }), {})
      this.setState({ meta: { ...this.state.meta, errors } })
      return
    }
    try {
      await signup.submit(this.state.form)
      swal('Thank You', 'We appreciate your interest!', 'success')
      this.setState({ ...initialState })
    } catch {
      swal('Oops!', 'An Error Occurred. Please Try Again.', 'error')
    }
  }
}

export default useStyles(EmailForm)
