import axios from 'axios'
import swal from 'sweetalert'

function serviceFactory(options = {}) {
  const headers = options.headers || {}

  const service = axios.create({
    baseURL: (options && options.uri) || process.env.REACT_APP_BASE_URI,
    timeout: (options && options.timeout) || 10000,
    headers
  })

  service.interceptors.request.use(async config => {
    //config.headers['cache-control'] = 'no-cache'
    config.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    return config
  })

  service.interceptors.response.use(
    function(res) {
      console.log(res)
      return res
    },
    function(error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            swal({
              title: 'Session Expired',
              text: 'Your session has expired. you are being redirected to the login page.',
              icon: 'warning',
              buttons: ['OK']
            })
            return Promise.reject(error)
          case 403:
            swal({
              title: 'Session Expired',
              text: 'Sorry, you are unauthorized to perform that task.',
              icon: 'warning',
              buttons: ['OK']
            })
            return Promise.reject(error)
          case 404:
          case 422:
          case 500:
          case 502:
          default:
            swal({
              title: 'Sorry, and unexpected error occurred. If problem persists, please contact support.',
              text: error.response.data.message,
              icon: 'warning',
              buttons: ['OK']
            })
            return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    }
  )

  return service
}

export default serviceFactory
