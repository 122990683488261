import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
// import ButtonBase from '@material-ui/core/ButtonBase'
// import PersonIcon from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'

const useStyles = withStyles({
  root: {
    backgroundColor: 'black',
    flexFlow: 'row',
    transition: 'all 1s',
    height: '5rem'
  },
  rootAtTop: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: 'none'
  },
  logo: {
    height: '2.5rem',
    padding: 0,
    display: 'inline'
  },
  userWrapper: {
    flex: 0,
    width: '5rem'
  },
  userButton: {
    padding: '1rem'
  },
  userIcon: {
    width: '2rem',
    height: '2rem',
    color: 'white'
  },
  logoWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    paddingLeft: '1rem',
    paddingTop: '0.25rem'
  },
  flex: { flex: 1 }
})

class Nav extends React.Component {
  state = {
    atTop: true
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  handleScroll = event => {
    const scrollY = window.scrollY
    if (scrollY <= 20 && !this.state.atTop) {
      this.setState({ atTop: true })
    } else if (scrollY > 20 && this.state.atTop) {
      this.setState({ atTop: false })
    }
  }
  render() {
    const { classes } = this.props
    return (
      <AppBar position="fixed" className={classes.root + ' ' + (this.state.atTop ? classes.rootAtTop : '')}>
        <div className={classes.logoWrapper}>
          <Link to="/">
            <img src={'/img/logo.svg'} alt="" className={classes.logo} />{' '}
          </Link>
        </div>
        <div className={classes.logoWrapper}>
          <Typography variant="h5" gutterBottom>
            NutroGenome &trade;
          </Typography>
        </div>
        <div className={classes.flex} />
        <div className={classes.userWrapper}>
          {/* <ButtonBase className={classes.userButton}>
            <PersonIcon className={classes.userIcon} />
          </ButtonBase> */}
        </div>
      </AppBar>
    )
  }
}

export default useStyles(Nav)
