import React from 'react'
import './App.css'
import { Typography } from '@material-ui/core'
import Hero from './components/Hero'
import Nav from './components/Nav'
import Section from './components/Section'
import EmailForm from './components/EmailForm'

function App() {
  return (
    <div className="App">
      <Nav></Nav>
      <Hero></Hero>
      <Section>
        <Typography variant="h3" className="serif">
          Is your nutrition formulated for your needs?
        </Typography>
        <hr />
        <br />
        <Typography variant="h4" style={{ lineHeight: 1.6 }}>
          Using advances in Machine Learning and AI,
          <br />
          personalized precision nutrition is possible.
        </Typography>
        <br />
        <br />
        <div className={'svg-shadow'}>
          <img src={'/img/logo.svg'} style={{ maxWidth: '50vw' }} alt="" />
        </div>
        <br />
        <br />
        <Typography variant="h4" style={{ lineHeight: 1.6 }}>
          NutroGenome's mission is to provide deep Phenotyping, complete Digital Histories of each person, with a
          complete genomic map for an accurate correlation between nutrition, diet and health issues focusing on Root
          Cause Analysis of illness instead of continuing with shallow Medicine and dependence on medication that only
          scratches the surface symptoms.
        </Typography>
      </Section>
      <Section
        style={{
          backgroundImage: 'url(/img/bg1.jpg)',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0,0,0,0.4)',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div style={{ textAlign: 'left', maxWidth: '40rem', margin: '0 auto', padding: '4rem 0' }} className="serif">
          <Typography variant="h2" className="center" gutterBottom>
            The Optimal Nutrition Formula
          </Typography>
          <fieldset style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '2rem' }}>
            <div style={{ maxWidth: '40rem', margin: '0 auto' }}>
              <Typography variant="h3" className="mb-1">
                &nbsp;&nbsp; Genetics
              </Typography>
              <Typography variant="h3" className="mb-1">
                + Machine Learning
              </Typography>
              <Typography variant="h3" className="mb-1">
                + Conditions
              </Typography>
              <Typography variant="h3" className="mb-1">
                + Preferences
              </Typography>
              <Typography variant="h3" className="mb-1">
                + Goals
              </Typography>
              <hr />
              <Typography variant="h3" className="center">
                Optimal Nutrition
              </Typography>
            </div>
          </fieldset>
        </div>
      </Section>
      <Section style={{ backgroundColor: '#ccc' }}>
        <Typography variant="h3" className="center serif">
          How You Can Help:
        </Typography>
        <hr />
        <Typography variant="h5" className="center">
          Sign Up To Learn More
        </Typography>
        <EmailForm></EmailForm>
      </Section>
    </div>
  )
}

export default App
