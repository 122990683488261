import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const useStyles = withStyles({
  hero: {
    height: '100vh',
    backgroundImage: 'url(/img/hero.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    color: 'white',
    lineHeight: '2rem',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    boxShadow: '0 1px 2px rgba(0,0,0,0.5)'
  }
})

class Hero extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.hero}>
        <div className={'svg-shadow'}>
          <img src={'/img/logo.svg'} style={{ maxWidth: '50vw' }} alt="" className={classes.logo} />
        </div>
        <br />
        <Typography variant="h2">NutroGenome</Typography>
        <br />
        <Typography variant="h4" className="serif">
          Genetically Formulated Personal Nutrition
        </Typography>
      </div>
    )
  }
}

export default useStyles(Hero)
